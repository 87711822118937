body {
  background-image: url('/public/fondo.webp'); 
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

strong {
  background: #fe0072;
}

.pi-rosa {
  color: #fe007288 !important;
}

.p-badge {
  background: #fe0072b5;
}

.App {
  background-color: #282c3485;
}

.App-logo {
  width: 100%;
  pointer-events: none;
}

.App-header {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.divider {
  background: #d62173;
  height: 3px;
  width: 100%;
}

.App-estetica {
  min-height: 53vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.textFooter {
  font-style: italic;
  font-weight: 700;
}

.footerEx {
  min-height: 5vh;
  color: white;
  background: #fe0072b5;
}
.App-link {
  color: #61dafb;
}

.kapono {
    z-index: 99;
    background: rgb(80, 1, 54);
    position: fixed;
    display: flex;
    align-content: space-around;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
    align-items: center;
    height: 100%;
    top: 0;
    bottom: 0;
}